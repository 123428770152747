<template>
  <div class="chat-container vstack text">
    <div v-if="!nameString">
      <label>您的名字 : </label>
      <input
        style="margin-top: 0.4em"
        id="name"
        placeholder="姓名或手机号码"
      />
      <br />
      <button @click="setName()" class="save-btn" type="button">确定</button>
    </div>
    <template v-else>
      <div class="messages-container -fit -scrollable">
        <div
          v-for="message in messages"
          :key="message.name + message.time + message.message"
          :style="
            ['display: flex;'] +
            [
              message.from_me
                ? 'flex-direction: row-reverse'
                : 'flex-direction: row',
            ]
          "
        >
          <p :class="message.from_me ? 'bg-light' : 'bg-dark'">
            <span v-if="!message.is_cmd" style="font-weight: 500">{{ message.message }}</span>
            <span v-else style="font-weight: 500" @click="(message.from_me || (!message.from_me && message.message.indexOf('|doc|') !== -1) ) || processSysCommand(message.name, message.message, message.time)">{{ message.tips }}<div v-if="!message.from_me && message.message.indexOf('|location|') !== -1 && !state.client"><br><button @click="setLocationInfo(message)" class="save-btn" type="button">采用该地址</button></div></span>
            <br />
            <small style="font-size: 0.68em">
              <span v-if="!message.from_me">- {{ message.name }}</span> @{{ message.time }}
            </small>
          </p>
        </div>
      </div>
      <input
        placeholder="输入沟通内容..."
        v-on:keyup.enter="sendMessage($event)"
        type="text"
        id="message-input"
      />
    </template>
  </div>
</template>

<script>
import { messages } from "@/lib/emitter"

export default {
  name: "app-chat",
  props: {
    name: {
      type: String,
    },
  },
  data() {
    return {
      messages: [],
      nameString: this.name,
    }
  },
  methods: {
    sendMessage(event) {
      let message = event.target.value
      if(message == '') {
        return;
      }
      let name = this.nameString
      let current = new Date()
      let ampm = current.getHours() < 12 ? "AM" : "PM"
      let time =
        current.getHours() +
        ":" +
        current.getMinutes() +
        ":" +
        current.getSeconds() +
        " " +
        ampm
      let tips = ""
      let is_cmd = message.startsWith('sys|')
      if(is_cmd) {
        this.processSysCommand(name, message, time)
        let cmd = message.split('|')
      let op = cmd[1], param = cmd[2]
      //console.log("op=" + op, "param=" + param)
      switch(op) {
        case "mode":
          tips = ""
          break
        case "doc":
          tips = "点此查看文书"
          break
        case "tips":
          tips = param
          break
        case "location":
          break
        case "requestloc":
          tips = "发起获取位置请求"
          break
        case "requestUserDevice":
          tips = "发起获取摄像头请求"
          break
        case "reportUserDevice":
          tips = "上报摄像头列表"
          break
        default:
          tips = "未知指令: " + op
          break
      }
        
      }
      messages.emit("chatMessage", { name, message, time, is_cmd, tips })
      this.messages.push({
        name: name,
        message: message,
        from_me: true,
        time: time,
        is_cmd: is_cmd,
        tips: tips
      })
      event.target.value = ""
    },
    setName() {
      let name = document.getElementById("name").value
      localStorage.setItem("name", name)
      this.nameString = name
      messages.emit("userInfo", {
        name: name
      })
    },
    scrollToEnd: function () {
      //let messages = this.$el.querySelector(".messages-container")
      //messages.scrollTop = messages.lastElementChild.offsetTop
      this.$el.querySelector(".messages-container").lastElementChild.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
    },
    setLocationInfo: function(message) {
        let cmd = message.message.split('|')
        let op = cmd[1], msg = cmd[2]
        //console.log("op=" + op, "param=" + msg)
        if(op !== 'location') {
            return
        }
        let geoinfo = JSON.parse(msg)
        let param = {
            'id': this.state.caseid,
            'tabId': this.state.tabid,
            'tabname': this.state.tabname,
            'qybh': this.state.qybh,
    //                                    'nowuserid': '0',
    //                                    'nowusername': '0',
    //                                    'flowstate': '0',
            'accident_address': geoinfo.accident_address,
            'bdlat': geoinfo.bdlat,
            'bdlng': geoinfo.bdlng,
        }
        
         if(param.id === '' || param.tabId === '' || param.qybh === '' || param.tabname === '' ) {
            throw new Error('缺少参数')
          }else{
            this.$layer.loading({time: 2, content:'正在更新位置信息...'})
            this.$http.post("/updateAiVueData_flowable", param)
                    .then( response => {
                        console.log(response)
                        if (response.result == "succ") {
                          this.$layer.closeAll()
                          this.$layer.msg('位置信息已更新')
                        }else{
                          this.$layer.msg(response.msg)
                        }
                    }).catch(e => {
                        console.log(e)
                        this.$layer.closeAll()
                        this.$layer.msg('位置更新失败-02')
                    })
          }
        
        
    },
    processSysCommand: function(name, message, time) {
      let cmd = message.split('|')
      let op = cmd[1], param = cmd[2]
      //console.log("op=" + op, "param=" + param)
      switch(op) {
        case "mode":
          messages.emit("switchMode", param)
          //messages.emit("unloadDoc")
          break
        case "doc":
          messages.emit("switchMode", "doc")
          messages.emit("changeVideoLayout")
          messages.emit("loadDoc", param)
          break;
        case "tips":
          messages.emit("layerAlert", param)
          return
          break
        case "location":
          messages.emit("layerMsg", "收到一条位置信息")
          messages.emit("switchMode", 'chat')
          break;
        case "requestloc":
          //messages.emit("layerMsg", "收到一条位置信息")
          messages.emit("onRequestLoc", param)
          return
          break
        case "endchat":
          messages.emit("releaseAVsource")
          messages.emit("endChat", param)
          return
        case "requestUserDevice":
          messages.emit("requestUserDevice", param)
          return
        case "reportUserDevice":
          messages.emit("reportUserDevice", param)
          return
        case "setUserDevice":
          messages.emit("setUserDevice", param)
          return
        default:
          //messages.emit("layerAlert", "未知指令: " + op)
          console.log("未知指令", op)
          break
      }
    },
  },
  updated() {
    this.$nextTick(() => this.scrollToEnd())
  },
  mounted() {
    messages.on("newMessage", (info) => {
      //console.log("newMessage", info)
      let tips = ""
      if(info.is_cmd) {
        this.processSysCommand(info.name, info.message, info.time)
      }
      
      this.messages.push({
        name: info.name,
        message: info.message,
        from_me: false,
        time: info.time,
        is_cmd: info.is_cmd,
        tips: info.tips
      })
    })
  }
}
</script>

<style lang="scss">
@import "../css/chat.scss";
</style>
